/***********************************************************************************************************************
 ********************************************  Utility *****************************************************************
 **********************************************************************************************************************/
$dog-blue: #88d3ed;
$dog-light-brown: #bb8e61;
$dog-brown: #543a2a;
$dog-red: #d34660;

html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #6c6f73;
}

/***********************************************************************************************************************
 ********************************************  Utility *****************************************************************
 **********************************************************************************************************************/
img {
  max-width: 100%;
}

.wrapper {
  padding: 8em 0;
}

.blockquote {
  font-size: 1.6rem;
  color: #333;
  border: none;
  padding: 0;
}

.blockquote-footer {
  margin: 1rem 0 0;
}


/***********************************************************************************************************************
 ********************************************  Jumbo Dog  **************************************************************
 **********************************************************************************************************************/
.jumbo-container {
  background: url('../img/jumbo-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 99;
  height: 100%;
}

.jumbo-band {
  position: absolute;
  bottom: 7%;
  z-index: 100;
  background-color: $dog-blue;
  padding: 5% 0;
  color: #fff;
  text-align: right;

  .row {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .apple-btn {
    height: 3.5em;
  }
}

.jumbo-dog {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;

  .row {
    height: 100%;
  }

  .ml-auto {
    z-index: 101;
  }

  img {
    max-width: 150%;
    max-height: 96%;
    margin-top: 2%;
  }
}

.dog-badge {
  height: 30%;
  position:absolute;
  top:-30%;
  left:10%;
}

/***********************************************************************************************************************
 ********************************************  Main Menu  **************************************************************
 **********************************************************************************************************************/

.bg-dt-theme {
  background-color: #f8f9fa !important;
  color: white !important;
  border-bottom: 1px solid #cfd2d6;

  .navbar-brand {
    font-size: 1.35em;
    font-weight: normal;
    color: #543a2a;
  }

  .navbar-nav .active > .nav-link {
    color: #543a2a;
  }
}


/***********************************************************************************************************************
 ********************************************  Sections  ***************************************************************
 **********************************************************************************************************************/
.section-title {
  margin-bottom: 3em;
}

#stage-caption {
  font-size: 1rem;
  font-weight: 200;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
}

.feature-content {
  padding: 8rem 0;
  overflow: hidden;
}

.feature-content img {
  margin-bottom: -8rem;
}

.feature-primary {
  background-color: $dog-blue;
  color: white;
}

.feature-primary h5 {
  color: white;
}

.feature-alt {
  background: #f0f0f0;
}

.minor-feature-btn {
  @extend .rounded-circle;
  @extend .btn-light;
  font-size: 1.5em;
  color: #cfd2d6;
  padding: 0.2em 0.4em;
  margin-top: -0.2em;

  img {
    width: 80px;
  }
}

.minor-feature-btn:hover {
  background-color: #BCE6F7;
}

.card-title {
  text-align: center;
}

.main-icon {
  background: #f7f8f9;
  border-radius: 1em !important;
}

.main-icon:hover {
  background: $dog-blue;
}

.main-icon-paint {
  background: $dog-blue !important;
}

.main-icon-paint:hover {
  background: $dog-blue !important;
}

#screen-container {
  display: none;
}



#get-app {
  background: url('../img/get-app-footer.png') $dog-blue;
  background-size: auto;
  background-position: bottom;
  background-repeat: no-repeat;

  .container {
    position: relative;
  }
}

#dog-with-ball {
  position: absolute;
  right: 20px;
  bottom: 20px;
}


.bone-img-container {
  img {
    width: 100%;
  }

  img.bottom-bone {
    margin-top: -40px;
  }
}

.btn-contact {
  @extend .btn-primary;

  background-color: $dog-brown;
  border-color: darken($dog-brown, 5%);
  outline: none !important;
  box-shadow: none !important;
}

.btn-contact:hover, .btn-contact:active, .btn-contact:focus {
  @extend .btn-primary:hover;

  background-color: darken($dog-brown, 10%);
  border-color: darken($dog-brown, 10%);
  outline: none !important;
  box-shadow: none !important;
}

#msg-success {
  background-image: url('../img/dog-thumbs-up.png');
  background-repeat: no-repeat;
  background-position: 5px 5px;
  background-size: 25%;
  padding-left: 11em;
}

/***********************************************************************************************************************
 ********************************************  Footer  *****************************************************************
 **********************************************************************************************************************/

#page-footer {
  background-color: $dog-blue;
  color: rgba(0,0,0,0.5);
}

.btn-social {
  @extend .btn-light;
  font-size: 1.5em;
  color: #cfd2d6;
  margin-top: 80px;
  padding: 0.2em 0.4em;
  margin-left: 5px;
  margin-right: 5px;
}

.social-btns {
  .twitter:hover {
    color: #00aced;
  }

  .pinterest:hover {
    color: #bd081c;
  }
}


/***********************************************************************************************************************
 ********************************************  Media Queries  **********************************************************
 **********************************************************************************************************************/

/* Target Phones */
@media (max-width: 320px) {
  .jumbo-band {
    min-height: calc(40% + 3em);
    .apple-btn {
      margin-top: 0;
    }
    p {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-down(lg) {
  .jumbo-band {
    padding: 3em 1em;
    h1 {
      font-size: 3.5em;
    }
  }
  .media-body {
    font-size: 0.90em;
  }

  .dog-phone {
    margin-top: 2.2em;
  }
}

@include media-breakpoint-down(md) {
  .jumbo-band {
    padding: 1em;
    h1 {
      font-size: 2.3em;
    }

    h5 {
      font-size: 1em;
      margin-left: 4em;
    }

    .apple-btn {
      height: 2.5em;
    }
  }

  .card-title {
    font-size: 1.4em;
  }

  .media-body {
    font-size: 0.94em;
  }

  #msg-success {
    background-position: 12px 15px;
    background-size: 28%;
    padding-left: 8em;
  }
}

@include media-breakpoint-down(sm) {
  #msg-success {
    background-position: 12px 15px;
    background-size: 28%;
    padding-left: 14em;
  }
}

/* iPhone 5s landscape */
@media only screen and (device-width : 568px) and (orientation : landscape) {
  .jumbo-band {
    min-height: calc(40% + 4em);
    h1 {
      margin-top: 0.4em;
    }
  }

  #msg-success {
    background-position: 5px 5px;
    background-size: 25%;
    padding-left: 17em !important;
  }
}

@include media-breakpoint-down(xs) {
  .jumbo-container {
    height: 68%;
  }
  .dog-badge, .bottom-bone {
    display: none;
  }

  .jumbo-band {
    position: inherit;
    height: 35%;
    background-color: $dog-blue;
    color: #fff;
    padding: 0 3%;

    .row {
      text-align: center;
    }
    h1 {
      font-size: 2.4em;
    }

    h5 {
      font-size: 1.2em;
      margin-left: 0;
    }

    .apple-btn {
      height: 3.0em;
    }
  }

  .jumbo-dog {
    img {
      max-width: 100%;
      max-height: 68%;
      margin-top: 5%;
    }
  }

  .card {
    font-size: 1.1em;

    .card-img-top {
      width: 3em;
      position: absolute;
      top: 1.3em;
      right: 1em;
    }

    .card-title {
      text-align: left;
    }

    .card-body {
      margin-top: 0.5em;
    }

    h4 {
      font-size: 1.5em;
    }
  }

  #features-screenshots {
    .m-icon-left {
      padding-left: 1em;
    }
    .m-icon-right {
      padding-right: 1em;
    }

    .dog-phone {
      text-align: center;
      padding: 0 !important;
    }

    .media-body.text-right {
      padding-left: 1em;
      font-size: 0.94em;
    }

    .media-body.text-left {
      padding-right: 1em;
      font-size: 0.94em;
    }
  }

  .carousel-item, .bone-img-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  #msg-success {
    background-position: 5px 15px;
    background-size: 40%;
    padding-left: 10em;
  }

  #contact-container > .col-md-6 {
    padding: 0 2em !important;
  }
}
